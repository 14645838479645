import * as React from "react"
import { useEffect, useRef } from "react"
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Logo from '../svg/logo'
import LogoClip from '../svg/logo-clip'
import { scroll } from "../components/infinite"
import { EnteringSmall, LeavingSmall } from "../components/cursor"
import WorkItem from "../components/work-item"

const IndexPage = ({ data }) => {
  const projects = data.projects.nodes;
  const homepage = data.homepage

  const handleScrollRef = useRef();
  useEffect(() => {
    handleScrollRef.current = () => {
      scroll()
    };

    window.addEventListener('scroll', handleScrollRef.current);

    return () => {
      // This function will be called when the component is unmounted
      window.removeEventListener('scroll', handleScrollRef.current);
    };
  }, []);

  return (
    <Layout>
        <HelmetDatoCms 
            seo={data.work.seoMetaTags}
            bodyAttributes={{
                class: 'dark-bg work-page'
            }}
        />
        <nav className="bottom-nav">
            <Link to="/" activeClassName="active" className="bottom-nav-item subnavigation" onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>{ homepage.pageName }</Link>
            <Link to="/work" activeClassName="active" className="bottom-nav-item subnavigation" onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>{ data.work.pageName }</Link>
        </nav>
        <main key="main">
            <section>
                <section className="media-scrollable scrollable">
                    <div className="media-content content">
                        {projects.map(client => (
                            <WorkItem client={client} />
                        ))}
                    </div>
                    <div className="media-content content">
                        {projects.map(client => (
                            <WorkItem client={client} />
                        ))}
                    </div>
                </section>
                <div className="large-logo">
                    <div className="large-logo-container">
                        <div className="logo-blur"></div>
                        <LogoClip />
                        <Logo />
                    </div>
                    <p className="lede">{ homepage.blurb }</p> 
                </div>
                <div className="hover-image-container">
                    {projects.map(client => (
                        <img className="cover-image hover-image" id={`hover-image-${client.slug}`} src={client.image.background} alt='' />
                    ))}
                </div>
          </section>
        </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query {
homepage: datoCmsHomepage {
    pageName
    blurb
}
work: datoCmsWork {
    pageName
    seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
    }
}
projects: allDatoCmsProject(sort: {fields: position, order: ASC}) {
    nodes {
        slug
        title
        client {
            name
        }
        image {
            featured: url(imgixParams: {w: "840"})
            background: url(imgixParams: {w: "1400", blendColor: "2A292D", blendMode: "normal", blendAlpha: 56})
        }
        vimeo
    }
    }
}
`