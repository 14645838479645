import * as React from 'react'
import { useRef } from "react"
import { Link } from 'gatsby'
import { EnteringView, LeavingView } from "../components/cursor"

const WorkItem = ({client}) => {
    const videoRef = useRef()

    const playVideoOnHover = () => {
        videoRef.current.play()
    }
    const stopVideoOnMouseLeave = () => {
        videoRef.current.pause()
    }
    const handleMouseEnter = () => {
        playVideoOnHover();
        EnteringView();
    }
    const handleMouseLeave = () => {
        stopVideoOnMouseLeave();
        LeavingView();
    }
    return (
        <div className="media-section">
            <Link to={`/project/${client.slug}`} id={client.slug} className='project-item media-item js-media-item' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className="media-hidden-container">
                    <div className="grid-media grid-media-image">
                        <img className="cover-image " src={client.image.featured} alt='' />
                    </div>
                    <div className="grid-media grid-media-video">
                        <video ref={videoRef} poster={client.image.featured} className="cover-image client-video" loop muted preload="metadata" playsInline>
                            <source src={`${client.vimeo}#t=0.1`} type="video/mp4"/>
                        </video>
                    </div>
                </div>
                <div className="title-container">
                    <div className="project-title-hider">
                        <h6 className="project-title">{client.title}</h6>
                    </div>
                    <div className="project-title-hider">
                        <h6 className="project-title client-title">{client.client.name}</h6>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default WorkItem